.task-note,
.deal-note {
  .task-note-date,
  .deal-note-date {
    @extend.font-italic;
    @extend.text-gray;
    @extend.mb-2;
    margin-left: .25rem;
    font-size: .875rem;
  }
}
