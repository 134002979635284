/*
  i wish this required CSS was better documented :(
  https://github.com/FezVrasta/popper.js/issues/674
  derived from this CSS on this page: https://popper.js.org/tooltip-examples.html
  */

.fc-event-time,
.fc-event-title {
  padding: 0 1px;
  white-space: normal;
}

.fc-list-event-time {
  display: none;
}

tr.fc-event,
.fc-daygrid-event-harness {
  cursor: pointer;
  background-color: white;
}

.fc-list-event-dot {
  border-width: 7px !important;
  border-radius: 7px !important;
}

.gray-cursive {
  font-style: italic;
  color: gray;
}

.fc-toolbar-title::first-letter,
.fc-list-day-text::first-letter,
.fc-list-day-side-text::first-letter {
  text-transform: uppercase;
}

.fc-theme-standard {
  .fc-list {
    border-radius: .25em;
  }

  .fc-list-day-cushion {
    background-color: $color-calendar-background;
  }

  .fc-button-primary {

    @extend .btn-light;

    &:focus {
      box-shadow: none;
    }

    &.fc-button-active,
    &.fc-button-active:not(:disabled) {

      @extend .btn-primary;
      pointer-events: none;
      border: 1px solid $color-buttons-primary !important;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

button.fc-today-button.fc-button.fc-button-primary {

  @extend .btn-light;

  &:disabled {
    color: white;
    background-color: $color-buttons-primary;
    border: 1px solid $color-buttons-primary;
    opacity: 1;
  }
}

.task-dialog,
.deal-dialog {
  .modal-dialog {
    max-width: 66%;
    margin: auto;

    .modal-body {
      height: 65vh;
      padding: 0 1.5rem;

      overflow-y: auto;

      &.task-create-dialog,
      &.deal-create-dialog {

        @extend .px-3;

        @extend .pt-3;
        height: auto;
      }

      .table-horizontal-bordered {
        .task-note-comment,
        .deal-note-comment {
          p {
            word-break: break-word;
          }
        }
      }
    }
  }
}


.task-note-input-section,
.deal-note-input-section {
  align-items: flex-start;
  padding-top: 10px;

  .task-note-input,
  .deal-note-input {
    width: calc(100% - 167px);
    padding: 12px;
    overflow: hidden;
    resize: none;
    border: none;
    outline: none;
  }
}

#task-notes-section .task-note-input {
  width: calc(100% - 180px);
}

.empty-task-notes {
  padding: 12px;
  border-top: 1px solid $color-border;
}

.task-state-icon-cancelled,
.deal-state-icon-cancelled {
  color: $color-task-state-icon-cancelled;
}

.task-state-icon-pending,
.deal-state-icon-pending {
  color: $color-task-state-icon-pending;
}

.task-state-icon-in-progress,
.deal-state-icon-in-progress {
  color: $color-task-state-icon-in-progress;
}

.task-state-icon-completed,
.deal-state-icon-completed {
  color: $color-task-state-icon-completed;
}

.task-note-deletion-icon {
  display: none;

  &:hover {
    color: $danger;
  }
}
