.cd-kanban-board {
  display: block;
  width: 100%;
  height: auto;
  overflow-x: auto;
  clear: both;

  & > .cd-kanban-board-titles,
  & > .cd-kanban-board-blocks {
    display: flex;
    padding: 0;
    margin: 0;
    clear: both;

    &::after {
      display: block;
      clear: both;
      content: "";
    }
  }

  & > .cd-kanban-board-titles {
    .cd-kanban-board-title {
      position: relative;
      display: flex;
      flex: 1;
      flex-flow: column wrap;
      align-items: end;
      min-width: 180px;
      height: 1.7em;
      margin: 0 10px 0 0;
      font-size: 18px;
      color: #000;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 80%;
        height: 100%;
        clear: both;
        content: "";
      }

      p {
        position: relative;
        z-index: 10;
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  & > .cd-kanban-board-blocks {
    .cd-kanban-board-block {
      flex: 1;
      min-width: 180px;
      min-height: 60vh;
      padding: 1rem;
      margin: 0 10px 0 0;
      overflow-y: auto;
      background: $color-background-kanban;
      border-top: 6px solid $color-secondary;
      border-right: 1px solid $color-border-kanban;
      border-bottom: 1px solid $color-border-kanban;
      border-left: 1px solid $color-border-kanban;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      &:last-child {
        margin: 0;
      }

      .cd-kanban-board-block-item {
        margin-bottom: 10px;
        cursor: pointer;
        background-color: $color-background-kanban-white;
        border-radius: 5px;
        box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
        transition: transform 300ms ease-in-out;
        transform: rotate(0deg);

        &:hover {
          background: $color-calendar-background;
        }

        &.ui-sortable-helper {
          transform: rotate(-4deg);
        }

        a {
          display: block;
          margin: 5px 0 0;
          font-style: italic;
          text-decoration: underline;
        }

        .card-body {
          .badge.badge-light {
            font-size: 16px;
            background-color: $color-background-kanban;
          }
        }

        .cd-kanban-board-block-item-title {
          margin: 0 0 .25rem;
          font-size: 1rem;
          font-weight: 700;
          line-height: 1rem;
        }

        .cd-kanban-board-block-item-footer {
          display: block;
          padding: .75rem;
          margin: .75rem -.75rem -.75rem;
          clear: both;
          font-size: .875rem;
          border-top: 1px solid #c0c2cd;
        }
      }
    }
  }
}

.cd-kanban-board-block-item-placeholder {
  display: block;
  width: 100%;
  height: 60px;
  margin: 0 0 10px;
  clear: both;
  border: 1px dashed #000;
}

.kanban-project-acronym {
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 10px;
  margin-left: 5px;

  @media (max-width: 1000px) {
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 0;
    margin-left: 0;
  }
}

.kanban-user-info {
  padding-right: 5px;
  padding-left: 0;
  overflow: hidden;
  line-height: 20px;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;

  @media (max-width: 1000px) {
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 0;
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.display-flex-align-center {
  display: flex;
  align-items: center;
}

.nowrap {
  white-space: nowrap;
}

.color-uf {
  color: #eb5757;
}

.card-header-style {
  padding: 10px;
  background-color: transparent;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: inherit;
  }

  .inner-card-body-kanban {

    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: inherit;
    }
  }
}

.title-style-text {
  width: 80%;
  padding-right: 0;
  padding-left: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon-pesos {
  display: block;
  width: 16px;
}

.padding-12 {
  padding: 12px;
}

.span-project-acronym {
  white-space: nowrap;
}
