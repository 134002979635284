main {
  min-height: calc(100vh - #{$navbar-min-height + $footer-min-height});
  padding-top: 1px;
  font-family: Roboto;

  &.site-main {
    margin-top: 0;
  }

  img.apartment-type-plan {
    max-width: 100%;
    margin-top: 88px;
  }

  img.apartment-type-admin {
    max-width: 75%;
    max-height: 400px;
    margin-top: 20px;
  }

  .header-logo {
    width: 75%;
    max-width: 500px;
  }

  .buildings-map {
    margin-top: 10px;
  }

  .alert {
    &.alert-error {
      padding-right: .5em;
      color: #f44336;
      background-color: #fff;
      border: solid 1px #f44336;
    }

    button.close {
      margin-top: 0;
      margin-right: .5em;
    }
  }

  @media (min-width: 992px) {
    .wrap-cards-30 {
      -webkit-box-flex: 0;
      flex: 0 0 33%;
      flex: 0 0 33%;
      max-width: 33%;
    }
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #000 transparent transparent;
  }

  .selection {
    .select2-selection {
      height: 38px;
      border-color: $color-border;

      .select2-selection__rendered {
        line-height: 36px;
      }

      .select2-selection__arrow {
        height: 38px;
      }
    }
  }

  .radio-button-group,
  .wrap-list {
    input[type="radio"],
    input[type="checkbox"] {
      width: 15px;
      height: 15px;
    }

    li {
      margin: 0 1em;
    }

    label {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
    }

    div {
      display: flex;
    }
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    background-color: #595959;
  }

  .btn-toolbar {
    form {
      margin: .75rem;

      .select2-selection {
        border-color: #000;
      }
    }
  }

  .table-horizontal-bordered {
    margin-bottom: 2rem;
    border-right: none;
    border-left: none;

    &.table-list {
      td {
        width: 50%;
      }
    }

    tr {
      border-collapse: collapse;
      border-top: 1px solid $color-border;
      border-right: none;
      border-bottom: 1px solid $color-border;
      border-left: none;

      th,
      td {
        padding-top: 12px;
        padding-bottom: 12px;
        line-height: 24px;
      }

      td {
        border-right: none;
        border-left: none;
      }
    }
  }

  .content-subtitle {
    margin-top: 20px;
  }

  section.quotation-pdf {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 2px solid $color-border;

    button {
      font-size: 1.25rem;
    }

    a.btn {
      text-decoration: none;
    }

    #back-to-quote {
      margin-right: 80px;
    }

    img.email-sent {
      max-width: 200px;
    }
  }


  section.quotation-detail {
    .company-contact {
      margin-bottom: 25px;
    }

    .name-title {
      margin-bottom: 50px;
    }

    .table {
      margin-bottom: 25px;
    }

    .table-bordered {
      border-right: none;
      border-left: none;

      &.two-columns {
        td {
          width: 50%;
        }
      }

      th,
      td {
        padding: .5rem;
        border-right: none;
        border-bottom: 1px solid $color-border;
        border-left: none;
      }
    }

    .footer {
      width: 80%;
      margin-top: $footer-margin-top;
      margin-right: auto;
      margin-left: auto;
      color: $color-text-tertiary;
      text-align: justify;
    }
  }

  section.model-detail {
    margin-top: 25px;

    .table-bordered {
      margin-bottom: 25px;

      td {
        width: 50%;
      }
    }
  }

  section.quotation-form {
    .text-info {
      float: right;
      font-size: $font-size-sm;
      color: $color-text-info !important;
    }
  }

  section.building-project {
    margin-top: 30px;
    margin-bottom: 100px;

    .row {
      padding: 15px;

      .col-sm-8 {
        padding-left: 15px;
      }

      .quote-button {
        position: absolute;
        right: 0;
        bottom: -14px;
        padding-right: 4rem;
        padding-left: 4rem;
      }

      @media (max-width: 991px) {
        .quote-button {
          position: relative;
          display: block;
          width: 100%;
          padding-right: 0;
          padding-left: 0;
          margin: 0;
          text-align: center;
        }
      }

      @media (max-width: 991px) {
        .col-sm-5 {
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
    }
  }

  section.dashboard {
    .dashboard-card {
      padding: 15px;
      margin-bottom: 2rem;
      background: $color-dashboard-background;
      border: 1px solid $color-dashboard-border;
      border-radius: 4px;

      h1 {
        margin-bottom: 3px;
        font-size: 36px;
      }

      h2 {
        font-size: 20px;
        font-weight: 700;
      }

      h3 {
        font-size: 16px;
      }

      h4 {
        font-size: 14px;
        font-weight: 300;
      }

      h5 {
        font-size: 13px;
        color: $color-dashboard-h5;
      }

      h6 {
        font-size: 12px;
      }

      .table {
        margin-bottom: 0;
        background: $color-tertiary;
        border: 0;
        border-radius: 4px;

        tr:last-child {
          border-bottom: 0;
        }

        tbody,
        th,
        td,
        tr:first-child {
          border-top: 0;
        }

        th {
          padding-left: 10px;
          font-weight: 500;
        }

        tr {
          padding-right: 5px;
          padding-left: 5px;
          font-weight: 500;
        }

        .value {
          padding-right: 10px;
          font-size: 20px;
        }
      }

      &.metrics-client {
        h3 {
          padding: .5em;
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        &.orient {
          border-bottom: solid 3px $color-orient;

          h3 {
            color: $color-orient;
          }
        }

        &.origin {
          border-bottom: solid 3px $color-origin;

          h3 {
            color: $color-origin;
          }
        }

        &.model {
          border-bottom: solid 3px $color-model;

          h3 {
            color: $color-model;
          }
        }

        &.utm {
          border-bottom: solid 3px $color-utm;

          h3 {
            color: $color-utm;
          }
        }

        &.place {
          border-bottom: solid 3px $color-place;

          h3 {
            color: $color-place;
          }
        }
      }
    }
  }

  section.dashboard.vendor {
    .dashboard-card {
      height: 100%;
      min-height: 154px;
      padding: 15px 0 0;

      .wrap {
        width: 100%;

        h2.info {
          margin-bottom: .5rem;
          font-size: 1.5rem;
        }

        h2.one-line {
          margin-bottom: 1.1rem;
        }

        .info {
          height: 30%;
        }

        .form-group {
          margin: 1rem 0 .8rem;
        }

        .cta {
          display: block;
          margin: 0 auto;

          a {
            float: left;
            margin: 1rem;
          }
        }
      }

      .btn-light {
        transition: background-color .3s ease-in, color .3s ease-in;
      }

      .btn-light:hover {
        transition: background-color .5s ease-in-out, color .5s ease-in-out;
      }
    }

    .search .category {
      margin-bottom: .9rem;
    }
  }

  .menu-selector {
    min-width: 190px;
    padding: .05rem;
    padding-top: 2px;
    border: solid 1px #000;
    border-radius: .25rem;

    .selection .select2-selection {
      border-color: #fff;
    }
  }

  @media screen and (max-width: 600px) {
    .row.mb-4 .mb-5 {
      margin-bottom: 2rem !important;
    }
  }

  section.building-projects {
    .building-project {
      height: 250px;
      border: 1px solid $color-border;

      .project-image {
        width: 60%;
        background-repeat: no-repeat;
        background-size: cover;

        .gradient {
          padding-left: 20px;
          background:
            linear-gradient(
              90deg,
              rgb(255 255 255 / 95%) 10%,
              rgb(255 255 255 / 85%) 20%,
              rgb(255 255 255 / 75%) 30%,
              rgb(255 255 255 / 65%) 40%,
              rgb(255 255 255 / 50%) 50%,
              rgb(255 255 255 / 40%) 60%,
              rgb(255 255 255 / 25%) 70%,
              rgb(255 255 255 / 15%) 80%,
              rgb(255 255 255 / 10%) 90%,
            );

          h1 {
            margin-bottom: 30px;
            font-size: 35px;
            font-weight: 700;
          }

          .project-commune {
            width: max-content;
            padding: 5px 10px;
            font-style: italic;
            border-top: 1px solid $color-primary;
          }
        }
      }

      .project-actions {
        width: 40%;
        padding: 0 20px;

        a {
          font-size: 20px;
          line-height: 40px;
        }
      }
    }
  }
}

.scroll-tables {

  @media (max-width: 577px) {
    max-width: 100vw;
    overflow: scroll;
  }
}

.scroll-report-tables {
  max-width: 100vw;
  overflow: scroll;

  .report-table {
    width: fit-content;

    table tbody tr {
      td:first-child {
        width: 250px;
      }

      td:not(:first-child) {
        text-align: right;
      }
    }
  }
}

@media screen and (max-width: 991px) and (min-width: 574px) {
  .dashboard-card.metrics-client.orient,
  .dashboard-card.metrics-client.origin {
    float: left;
    width: calc(50% - 15px);
    -webkit-box-flex: 0;
  }

  .dashboard-card.metrics-client.origin {
    margin-left: 30px;
  }
}

.two-columns {
  td {
    width: 50%;
  }
}
