$images-path: "../img/";
$fonts-path: "../fonts/";

$media-type: all;
$responsive: true;
$static-breakpoint: desktop;

$shadow-color: rgb(0 0 0 / 22%);

$font-size-base-in-px: 16px;

// font-size bootstrap reference for use font-size bootstrap mixin (responsive font size)
// see bootstrap/scss/_type.scss
// see bootstrap/scss/vendor/_rfs.scss
$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * .875;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.375;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-base;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$lead-font-size: $font-size-base * 1.25;
$small-font-size: 80%;
// end font-size

// set color and set theme color example
$blue-magnet: #28afce;
$success: #219653;
$danger: #cd1818;
$primary: #000;
$blue: #3073d9;


$magnet: $blue-magnet;

$theme-colors: (
  "magnet": $magnet
);

$line-height-base: 1.5;
$headings-line-height: 1.2;
$display-line-height: $headings-line-height;


$navbar-min-height: 70px;
$navbar-toggler-min-height: 99px;
$navbar-nav-item-link-padding-x: 14.4px;

$footer-min-height: 72px;
$footer-margin-top: 64px;
$footer-color:#fff;
$footer-bc-color: #2c2c2c;

// colors
$color-primary: $primary;
$color-secondary: #d9a130;
$color-tertiary: #fff;
$color-text: $primary;
$color-text-secondary: #303030;
$color-text-tertiary: #6d6d6d;
$color-text-quaternary: #fff;
$color-text-info: #8a8a8a;
$color-title: #303030;
$color-buttons-primary: #303030;
$color-buttons-primary-text: #fff;
$color-buttons-primary-hover: #070707;
$color-buttons-primary-hover-text: #fff;
$color-buttons-secondary: #d9a130;
$color-buttons-secondary-text: #fff;
$color-buttons-secondary-hover: #a37300;
$color-buttons-secondary-hover-text: #fff;
$color-buttons-tertiary: #fff;
$color-buttons-tertiary-text: $primary;
$color-buttons-tertiary-hover: $primary;
$color-buttons-tertiary-hover-text: #fff;
$color-buttons-tertiary-border: $primary;
$color-buttons-success: $success;
$color-buttons-success-hover: #1b7242;
$color-input-border: #8a8a8a;
$color-input-disable: #e9ecef;
$color-table-hover: #e9ecef;
$color-border: #dee2e6;
$color-dashboard-background: #f3f3f3;
$color-dashboard-border: #dfe0eb;
$color-dashboard-h5: #8a8a8a;
$color-tab-bg-header: #fff;
$color-tab-text: #333;
$color-tab-text-disable: #828282;
$color-tab-form-bg:#f7f7f7;
$color-stage-header-hover-bg:#303030;
$color-stage-header-hover-text:#fff;
$color-multiselect: #333;
$color-calendar-background: #f3f3f3;


$colors: (
  "magnet": $blue-magnet,
  "primary": $color-primary,
  "secondary": $color-secondary,
  "tertiary":  $color-secondary,
  "quaternary": $color-text-quaternary
);

/* dashboard */
$color-origin:#1d1d1d;
$color-model:#d9a130;
$color-utm:#eb3737;
$color-place:#1e9ceb;
$color-orient:#856254;

/* agenda */
$color-task-state-icon-cancelled:  $danger;
$color-task-state-icon-pending:  #d9a130;
$color-task-state-icon-in-progress: $blue;
$color-task-state-icon-completed: #219653;


/* alerts */
$alert-box-shadow: 3px 5px 8px 0 rgb(45 45 45 / 51%);
$alert-border: none;

$alert-primary-color: #004085;
$alert-primary-color-bc: #bddcff;
$alert-primary-color-border: $alert-primary-color;
$alert-primary-color-border-l: $alert-primary-color;

$alert-secondary-color: #1d1d1d;
$alert-secondary-color-bc: #d9d9d9;
$alert-secondary-color-border: #333;
$alert-secondary-color-border-l: $alert-secondary-color-border;

$alert-success-color: #0f4d2b;
$alert-success-color-bc: #dcefe6;
$alert-success-color-border: #219653;
$alert-success-color-border-l: $alert-success-color-border;
$alert-success-color-span: $alert-success-color;

$alert-danger-color: $danger;
$alert-danger-color-bc: #faebeb;
$alert-danger-color-border-l: $danger;
$alert-danger-color-border:#eb5757;

$alert-error-color: $danger;
$alert-error-color-bc: #faebeb;
$alert-error-color-border-l: $alert-error-color;
$alert-error-color-border: #eb5757;

$alert-warning-color: #856404;
$alert-warning-color-bc: #fef3d1;
$alert-warning-color-border-l: #ffde70;
$alert-warning-color-border: #856404;

$alert-info-color: #2a7b93;
$alert-info-color-bc: #d1ecf1;
$alert-info-color-border-l: #1f596b;
$alert-info-color-border: #1f596b;

$alert-dark-color: #fff;
$alert-dark-color-bc: #1d1d1d;
$alert-dark-color-border-l: $color-secondary;
$alert-dark-color-border: #1f596b;

/* kanban */
$color-background-kanban:  #f2f2f2;
$color-background-kanban-white:  #fff;
$color-border-kanban:  #f2f2f2;
