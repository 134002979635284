html {
  position: relative;
  min-height: 100%;
  font-size: $font-size-base-in-px;// 62.5% = 10px | 100% = 16px
}

body {
  .page-loading-icon {
    position: relative;
    left: 10px;
    display: none;
  }

  &.wait {
    cursor: wait !important;/* stylelint-disable-line */

    .page-loading-icon {
      display: inline-block;
    }
  }

  .container {

    @media (max-width: 768px) {
      max-width: calc(97vw);
      margin: 0 auto;
    }

    @media (min-width: 768px) {
      max-width: calc(90vw);
      margin: 0 auto;
    }

    @media (min-width: 1280px) {
      max-width: calc(100vw);
      padding: 0px calc(5% + 54px);/* stylelint-disable-line */
    }
  }
}

.dim {
  transition: background 2s;
}

.highlight {

  @each $color, $value in $theme-colors {
    &.alert-#{$color} {
      background: darken($value, 10%);
    }
  }
}

@include from-colors using ($name, $value) {

  .text-#{$name} {
    color: $value !important;/* stylelint-disable-line */
  }

  .text-focus-#{$name}:focus {
    color: $value !important;/* stylelint-disable-line */
  }

  .bg-layer-#{unquote($name)} {
    background-color: transparent !important;/* stylelint-disable-line */
    position: relative;

    &::before {
      background-color: $value;
      bottom: 0;
      left: 0;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      content: "";
    }
  }

  .border-#{$name} {
    border-color: $value !important;/* stylelint-disable-line */
  }

  .text-before-#{$name} {
    &::before {
      color: $value !important;/* stylelint-disable-line */
    }
  }

  .text-after-#{$name} {
    &::before {
      color: $value !important;/* stylelint-disable-line */
    }
  }

  .bg-before-#{$name} {
    &::before {
      background-color: $value !important;/* stylelint-disable-line */
    }
  }

  .bg-after-#{$name} {
    &::before {
      background-color: $value !important;/* stylelint-disable-line */
    }
  }
}

/* General css */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
}

h1 {
  color: $color-title;
}

h2 {
  margin-bottom: 20px;
  color: $color-title;
}

h3 {
  margin: 0;
  font-size: 2.5rem;
  color: $color-title;
}

h4 {
  margin-bottom: 1rem;
  line-height: 29px;
  color: $color-title;
}

h5 {
  margin-bottom: .5rem;
  font-size: 1.25rem;
  color: $color-title;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.margin-bottom {
  margin-bottom: 2rem;
}

a,
.button-link {
  color: $color-primary;
  text-decoration: underline;

  &:hover {
    color: $color-buttons-secondary-hover;
  }

  i.fa,
  i.fas {
    margin-right: .5rem;
  }
}

a.button {
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a.button,
input[type="submit"],
input[type="button"],
button {

  padding: .5rem .75rem;
  font-family: "Roboto";
  line-height: 1.5rem;
  border-radius: .25rem;

  i.fa {
    margin-right: .5rem;
  }

  &.row-button {
    background-color: transparent;
    border: none;
    line-height: 1rem;

    &:hover {
      border: none;
    }
  }
}

input[type=search] {
  outline-offset: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $color-multiselect;
}

/* Mixin transition */

@mixin inHover($in-animation) {
  transition: background-color .15s $in-animation 0s, color .15s $in-animation 0s;
}

@mixin outHover($out-animation) {
  transition: background-color .3s $out-animation 0s, color .3s $out-animation 0s;
}

.button {

  @include outHover(ease-in-out);

  &:hover {

    @include inHover(ease-in-out);
  }
}

.action-button {
  @extend .button;
  @extend .text-center;
  min-width: 120px;
}

.btn {
  min-width: 100px;
}

.btn-group {
  gap: 0.75rem;
  &.form-buttons .button {
    @extend .text-center;
    min-width: 9.25rem;
  }
}

.btn-primary {
  border: 1px solid transparent;
  background-color: $color-buttons-primary;

  &:hover {
    border: 1px solid transparent;
    background-color: $color-buttons-primary-hover;
  }

  &:focus {
    box-shadow: 0 0 0 0.125rem rgba($color-primary, .3);
    outline-color: $color-primary;
  }

  &.focus {
    box-shadow: 0 0 0 0.125rem rgba($color-primary, .3);
  }

  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.125rem rgba($color-primary, .3);
  }

  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.125rem rgba($color-primary, .3);
  }

  .show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.125rem rgba($color-primary, .3);
  }
}

.btn-danger {
  background-color: $danger;
  border: 1px solid transparent;

  &:hover {
    background-color: darken($danger, 15%);
    border: 1px solid transparent;
  }

  &:focus {
    box-shadow: 0 0 0 0.125rem rgba($danger, .5);
  }

  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.125rem rgba($danger, .5);
  }

  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.125rem rgba($danger, .5);
  }

  .show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.125rem rgba($danger, .5);
  }
}

.btn-secondary {
  background-color: $color-buttons-secondary;
  border: 1px solid transparent;

  &:hover {
    background-color: $color-buttons-secondary-hover;
    border: 1px solid transparent;
  }

  &:focus {
    box-shadow: 0 0 0 0.125rem rgba($color-buttons-secondary, .5);
  }

  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.125rem rgba($color-buttons-secondary, .5);
  }

  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.125rem rgba($color-buttons-secondary, .5);
  }

  .show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.125rem rgba($color-buttons-secondary, .5);
  }
}

.btn-success {
  background-color: $color-buttons-success;
  border: 1px solid transparent;
  color: white;

  &:hover {
    background-color: $color-buttons-success-hover;
    border: 1px solid transparent;
  }

  &:focus {
    box-shadow: 0 0 0 0.125rem rgba($success, .5);
  }

  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.125rem rgba($success, .5);
  }

  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.125rem rgba($success, .5);
  }

  .show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.125rem rgba($success, .5);
  }
}

.btn-light {
  background-color: $color-buttons-tertiary;
  border: 1px solid $color-buttons-tertiary-border;
  color: $color-buttons-tertiary-text;

  &:hover {
    background-color: $color-buttons-tertiary-hover;
    border: 1px solid $color-buttons-tertiary-border;
    color: $color-buttons-tertiary-hover-text;

    &.row-button:hover {
      background-color: $color-buttons-tertiary-hover;
    }

  }
}

.btn-outline-danger {
  border: 1px solid $danger;
  background-color: $color-buttons-tertiary;

  &:hover {
    border: 1px solid $danger;

    &.row-button:hover {
      background-color: $danger;
      color: white;
    }
  }
}

.btn-outline-success {
  border: 1px solid $success;
  background-color: $color-buttons-tertiary;

  &:hover {
    border: 1px solid $success;

    &.row-button:hover {
      background-color: $success;
    }
  }
}

.btn-outline-blue {
  border: 1px solid $blue;
  color: $blue;
  background-color: $color-buttons-tertiary;

  &:hover,
  &.row-button:hover {
    color: #fff;
    background-color: $blue;
  }
}

.button-link {
  padding: 0.5rem;
  margin: 0;
  border: none;
  border-radius: none;
}

.badge-info {
  background-color: $blue;
  min-width: 30px;
  line-height: 1.1em;
  vertical-align: text-bottom;

}

.page-link {
  color: $color-text;

  &:hover {
    background-color: $color-buttons-secondary-hover;
    border-color: $color-buttons-secondary-hover;
    color: $color-buttons-secondary-hover-text;
  }
}

.page-item.active .page-link {
  background-color: $color-secondary;
  border-color: $color-secondary;
}

.breadcrumb {
  padding: 0rem 0rem;
  margin-bottom: 0rem;
  background-color: transparent;

  a {
    text-decoration: none;
  }
}

ul.pagination.justify-content-center {
  margin-left: 50%;

  @media (max-width: 577px) {
    margin-left: 0%;
  }
}

.wrap-list {
  margin-left: 5px;

  .form-check {
    margin-bottom: 0.5rem;
  }
}

.form-group {
  ul {
    list-style-type: none;
    padding-left: 0;
    padding-top: 8px;
  }

  li {
    display: inline;
  }

  label {
    display: inline-flex;
    margin-right: 10px;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 500;
    color: #000;

    input {
      margin-right: 10px;
    }

  }

  .form-control:focus {
    border-color: $color-primary;
    box-shadow: none;
  }
}

.form-control:focus {
  border-color: $danger;
  box-shadow: none;
}

/* MODALS */
.modal {
  width: 96vw;

  .modal-title {
    margin-bottom: 0;
    line-height: 2;
  }

  .modal-dialog {
    min-width: 720px;

    .modal-content {
      .content {
        display: flex;
        flex-direction: column;
      }

      .table-horizontal-bordered {
        margin-bottom: 1.5rem;

        &.table-no-border-top {
          margin-top: 0.5rem;

          tbody tr:first-child {
            border-top: none;

            th,
            td {
              border-top: none;
            }
          }
        }
      }


      .input-group-text:hover {
        color: #fff;
        background-color: $color-buttons-tertiary-hover;
      }
    }

    .modal-content.up-file {
      .modal-body {
        text-align: center;
      }

      .input-group {
        input {
          background: #e8e8e8;
        }

        &.cta {
          -ms-flex-align: center;
          -ms-flex-pack: center;
          align-items: center;
          border: solid 1px $color-primary;
          border-radius: 4px;
          cursor: pointer;
          justify-content: center;
          padding: 5px 1em;
          margin: 1em 0;
          width: auto;
          -webkit-box-align: center;
          -webkit-box-pack: center;

          input {
            padding: 0;
            background: transparent;
            border: none;
          }

          &:hover {
            background-color: $color-text-secondary;
            color: $color-text-quaternary;

            input {
              color: $color-text-quaternary;
            }
          }
        }
      }
    }

    .modal-footer {}
  }
}

section.section-options {
  margin-bottom: 15px;

  .btn {
    margin-right: 5px;
  }
}

#loader-overlay {
  background-color: rgba(0, 0, 0, 30%);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  .loader {
    /* Safari */
    animation: spin 2s linear infinite;
    border: 16px solid $color-border;
    border-top: 16px solid $color-secondary;
    border-radius: 50%;
    position: relative;
    height: 120px;
    left: 50%;
    top: 40%;
    width: 120px;
    -webkit-animation: spin 2s linear infinite;
  }

  /* Safari */

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.alert-danger {
  background-color: transparent;
  border-color: $color-secondary;
  color: $color-text;

  strong {
    color: $color-secondary;
  }
}

.table-hover tbody tr:hover {
  background-color: $color-table-hover;
  color: $color-text;
}

/* Error import */
section.error-import {
  li {
    padding: 1em 0;

    span {
      font-weight: 500;
    }

    div {
      padding: .5em;

      code {
        color: #db1a74;
      }
    }
  }
}

.disabled {
  background-color: $color-input-disable;
  opacity: 1;
}

.responsive {
  width: 100%;
  height: auto;
}

.responsive-logo{
  width: 180px;
  height: auto;
}

footer {
  #main-logo {
    width: 70px;
  }
}

.cell-is-active {
  background-color: #ffcc99;
}

/* ALERTS*/

.alert {
  border-left: solid 4px #333;
  box-shadow: $alert-box-shadow;
  transition: all .3s ease-in-out;
  width: 100%;

  .alert-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.alert-primary {
    background-color: #fff;
    border: $alert-border;
    border-left: solid 4px $alert-primary-color-border;

    &.alert-dismissible {
      background-color: $alert-primary-color-bc;
      border-color: $alert-primary-color-border;
      color: $alert-primary-color;
    }
  }

  &.alert-secondary {
    background-color: #fff;
    border: $alert-border;
    border-left: solid 4px $alert-secondary-color-border-l;

    &.alert-dismissible {
      background-color: $alert-secondary-color-bc;
      border-color: $alert-secondary-color-border;
      color: $alert-secondary-color;
    }
  }

  &.alert-success {
    background-color: #fff;
    border: $alert-border;
    border-left: solid 4px $alert-success-color-border-l;

    &.alert-dismissible {
      background-color: $alert-success-color-bc;
      border-color: $alert-success-color-border;
      color: $alert-success-color;
    }

    i {
      color: $alert-success-color-span;
    }
  }

  &.alert-danger {
    color: $alert-danger-color;
    background-color: #fff;
    border: $alert-border;
    border-left: solid 4px $alert-danger-color-border-l;

    &.alert-dismissible {
      background-color: $alert-danger-color-bc;
      border-color: #eb5757;
      border-left: solid 4px $alert-danger-color-border-l;
      color: $alert-danger-color;
    }
  }

  &.alert-error {
    color: $alert-error-color !important;
    background-color: #fff !important;
    border: $alert-border !important;
    border-left: solid 4px $alert-error-color-border-l !important;

    &.alert-dismissible {
      background-color: $alert-error-color-bc;
      border-color: $alert-error-color-border;
      border-left: solid 4px $alert-error-color-border-l;
      color: $alert-error-color;
    }
  }

  &.alert-warning {
    background-color: #fff;
    border: $alert-border;
    border-left: solid 4px #ffde70;

    &.alert-dismissible {
      background-color: #fef3d1;
      border-color: #856404;
    }
  }

  &.alert-info {
    background-color: #fff;
    border: $alert-border;
    border-left: solid 4px $alert-info-color-border-l;
    color: $alert-info-color;

    &.alert-dismissible {
      background-color: $alert-info-color-bc;
      border: $alert-border;
      border-left: solid 4px $alert-info-color-border-l;
    }
  }

  &.alert-dark {
    background-color: $alert-dark-color-bc;
    border-left: solid 4px $alert-dark-color-border-l;
    color: $alert-dark-color;

    span,
    a {
      color: $alert-dark-color;
    }

    &.alert-dismissible {
      font-weight: 300;
      border-top: solid 1px $alert-dark-color;
    }
  }

  i {
    margin: .5em;
  }

  strong {
    color: inherit;
  }
}

// toggle switch
.custom-switch {

  .custom-control-label {
    &::before {
      transform: scale(1.5, 1.5);
    }

    &::after {
      transform: scale(1.3, 1.3);
    }
  }

  .custom-control-input:checked~.custom-control-label::after {
    transform: translateX(0.875rem) scale(1.3, 1.3);
  }
}
