footer.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $footer-min-height;
  font-size: 14px;
  color: $color-text-quaternary;
  background-color: $footer-bc-color;

  .wrap-footer {
    height: 24px;

    a {
      color: $footer-color;
      text-decoration: none;

      &:hover {
        opacity: .9;
      }

      p:hover {
        text-decoration: underline;
      }
    }

    .icon-footer {
      margin-right: 10px;
      font-size: 21px;
    }

    .vertical-divider {
      height: 100%;
      border-left: 1px solid $color-border;
      opacity: .5;
    }

    .footer-element-space {
      margin-right: 24px;
      margin-left: 24px;
    }

    p {

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}
