// Here is all the css related with the reservation process
main.rpe {
  h1 {
    margin-bottom: 1em;
  }

  .wrap-process {
    h1 {
      margin-top: 2em;
    }
  }
}

.reservation-requests {
  .table-horizontal-bordered tr td {
    vertical-align: baseline;
  }

  .rpe-cta {
    padding: 0;
    border: none;

    button {
      &.btn-danger {
        color: $danger;
        background-color: inherit;
        border-color: $danger;

        &:hover {
          color: #fff;
          background-color: $danger;
        }
      }

      &.btn-success {
        color: $success;
        background-color: inherit;

        &:hover {
          color: #fff;
          background-color: $success;
        }
      }

      i {
        margin-right: .2em;
      }
    }

    @media (max-width: 1200px) {
      flex-direction: column;
      width: max-content;
    }
  }
}

/* RPD PROCESS */
section.info-rpd {
  margin-bottom: 3em;

  .client-info {
    display: inline-grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0;
    padding: 0;

    .grid-item .title {
      display: flex;
      width: 100%;
      padding: 0 0 .2em .75em;
      font-weight: 600;
      border-bottom: 1px solid rgb(0 0 0 / 12.5%);
    }

    p {
      padding: .2em 0 0 .75em;
    }

    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.disable-process {
  pointer-events: none;
  opacity: .4;
}

section.tabs {
  .nav.nav-tabs {
    margin-bottom: 2rem;
    border: none;

    .nav-item {
      position: relative;
      min-width: 270px;
      text-align: center;
      border: none;

      .nav-link {
        text-decoration: none;
        border: none;
        opacity: .6;

        h4 {
          /* font-size: 1.75rem; */
          padding-bottom: .2em;
          font-weight: 500;
          color: $color-tab-text-disable;

          @media (max-width: 992px) {
            font-size: 1.5rem;
          }
        }

        &::before {
          position: absolute;
          bottom: -7px;
          left: 1rem;
          width: calc(100% - 2rem);
          height: 0;
          content: "";
          background-color: $color-secondary;
          border-radius: 8px;
          opacity: 0;

          @include tab-out-hover(.3s, ease-in-out);
        }

        &.active {
          opacity: 1;

          h4 {
            color: $color-tab-text;
          }

          &::before {
            bottom: 4px;
            height: 4px;
            opacity: 1;

            @include tab-in-hover(.3s, ease-in-out);
          }
        }
      }

      @media (max-width: 992px) {
        min-width: 30vw;
      }
    }

    @media (max-width: 992px) {
      flex-wrap: inherit;
    }
  }

  .tab-content {
    margin-bottom: 10px;

    .card-header {
      i {
        position: absolute;
        right: 16px;
        margin-right: .5em;
        margin-left: 1em;
        font-size: 1rem;
        color: $color-text;
      }

      @media (max-width: 992px) {
        min-height: 4.75em;
      }
    }

    .tab-pane {
      .process-completed {
        border-top: 1px solid rgb(0 0 0 / 12.5%);

        button {
          margin-top: 2em;
        }
      }

      .card {
        border-bottom: 0;
        border-radius: 0;

        &:last-of-type {
          border-bottom: 1px solid rgb(0 0 0 / 12.5%);
        }

        .card-header {
          padding: 0;
          background-color: $color-tab-bg-header;

          @include tab-in-hover(.3s, ease-in-out);

          &:first-child {
            border-radius: 0;
          }

          .btn {
            position: relative;
            justify-content: flex-start !important;
            width: 100%;
            padding: 1.75rem 1.25rem;
            margin: 0;
            font-weight: 500;
            text-align: left;
            border-radius: 0;

            p {
              width: calc(100% - 20px);
              margin-bottom: 0;
            }

            span.required {
              margin-left: 1em;
              font-size: .8em;
              font-weight: 400;
              color: #eb5757;
            }

            span.confirmed {
              padding: .25em 1em;
              margin-right: 3em;
              font-size: .75rem;
              color: #fff;
              background-color: #219653;
              border-radius: 20px;
            }

            &:focus,
            .btn.focus {
              box-shadow: none;
            }

            &:hover {
              color: $color-stage-header-hover-text;
              background-color: $color-stage-header-hover-bg;

              @include tab-out-hover(.3s, ease-in-out);

              i {
                color: #fff;
              }
            }

            &:hover .required {
              color: #fff;

              @include tab-out-hover(.3s, ease-in-out);
            }

            @media (max-width: 992px) {
              min-height: 4.75em;
            }
          }
        }

        .collapse,
        .collapsing,
        .collapse.show {
          background-color: $color-tab-form-bg;

          .card-body {
            padding: 1.25rem;

            label {
              font-size: .875rem;
              font-weight: 500;
              color: $color-text;
            }

            p {
              span {
                display: inline;
                font-size: inherit;
                font-weight: 600;
              }
            }

            .container.form-content {
              .menu-selector {
                border-color: $color-input-border;
              }

              .wrap-list {
                .form-check,
                .form-option {
                  padding-left: 2.25rem;
                  margin: .5em 0;

                  label {
                    font-size: 1em;
                    font-weight: 400;
                  }
                }
              }

              .input-from-option {
                height: auto;
                pointer-events: inherit;
                opacity: 1;
                transition: height 400ms ease-in-out, opacity 80ms ease-in-out;

                &.input-hide {
                  height: 0;
                  pointer-events: none;
                  opacity: 0;
                  transition: height 400ms ease-in-out, opacity 80ms ease-in-out;
                }
              }

              .form-group {
                input {
                  color: $color-text;

                  &::placeholder {
                    color: #757575;
                  }
                }

                &.date {
                  margin-left: 0;
                }

                input {
                  color: $color-text;

                  &::placeholder {
                    color: #757575;
                  }

                  &.form-control.date {
                    min-width: 120px;
                    color: #757575;

                    .form-control.disabled {
                      color: #757575;
                    }
                  }
                }

                textarea {
                  min-height: 8em;
                  color: $color-text;

                  &::placeholder {
                    color: #757575;
                  }
                }

                .select2-container--default {
                  .select2-selection--single .select2-selection__rendered {
                    color: #757575;
                  }

                  &.select2-container--focus .select2-selection--single .select2-selection__rendered,
                  .select2-container--default.select2-container--below .select2-selection--single .select2-selection__rendered {
                    color: $color-text;
                  }
                }

                a.download-file {
                  position: absolute;
                  top: 2.2rem;
                  right: 0;
                  padding: .1em 1em;
                  margin: 0;
                  margin-top: .5em;
                  font-size: 80%;
                  color: #6c757d;
                  background-color: transparent;
                  border: none;

                  &:hover {
                    color: #485057;
                    text-decoration: underline;
                    background-color: #e9ecef;
                  }
                }
              }

              .custom-file-label {
                &.upload {
                  display: block;
                  width: auto;
                  height: 37px;
                  padding-right: 2.5em;
                  overflow: hidden;
                  font-size: 1rem;
                  font-weight: inherit;
                  text-overflow: ellipsis;
                  white-space: nowrap;

                  &::after {
                    font-family: "Font Awesome\ 5 Free";
                    font-weight: 900;
                    content: "\f093";
                  }
                }

                &.placeholder {
                  color: #757575;
                }
              }
            }
          }
        }
      }

      form {
        button.process-completed {
          margin-top: 2em;
        }
      }
    }
  }

  .date input[type="date"]:invalid::-webkit-datetime-edit {
    color: #757575;
  }
}

#dashboardTab {
  .nav-link {
    .fas.fa-check-circle {
      position: absolute;
      top: 32%;
      right: 2em;
      font-size: 1em;
      color: #282823;
      opacity: .5;

      @media (max-width: 992px) {
        right: 0;
      }

      &:hover {
        color: #282823;
      }
    }

    &.active .fas.fa-check-circle {
      color: #219653;
      opacity: 1;

      @include tab-out-hover(.3s, ease-in-out);
    }
  }
}

.rpd-progress-completed {
  font-weight: 600;
  color: #219653;
}

.rpd-progress-slow {
  font-weight: 600;
  color: #eb5757;
}
