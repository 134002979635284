nav.navbar {
  height: 64px;
  padding: 0 5%;
  color: $color-text-quaternary;
  background-color: $color-primary;
  box-shadow: 0 -1px 8px 0 $shadow-color;

  .navbar-brand {
    padding: 0;
  }

  .navbar-toggler {
    padding: $navbar-toggler-padding-y 0 $navbar-toggler-padding-y px($navbar-toggler-padding-x);
    //min-height: $navbar-min-height;
    margin-right: 0;
    font-size: px($navbar-toggler-font-size);
    border: none;

    &:focus {
      outline: none;
    }
  }

  .navbar-nav .nav-item {
    &.active a {
      text-decoration: underline;
    }

    a {
      text-decoration: none;
      letter-spacing: .025em;

      &:hover {
        text-decoration: underline;
      }
    }

    &.dropdown {
      .dropdown-menu {
        right: -10px;
        left: unset;
        float: right;
        padding-top: 0;
        padding-bottom: 0;
        background-color: #fff;
        border: 1px solid #eaeaea;
        border-top: solid 3px #a6a6a6;
        border-radius: 0 0 .5rem .5rem;

        a {
          padding-top: .375em;
          color: $color-text;

          &.cotizador-log-out {
            font-weight: 600;
            color: #d9a130;
          }

          &:last-child {
            border-radius: 0 0 .5rem .5rem;
          }

          &:hover {
            color: #fff;
            text-decoration: none;
            background-color: #333;
          }
        }
      }
    }
  }

  a {
    color: $color-text-quaternary;

    &#dropdown-logged-in {
      text-decoration: none;
    }
  }


  .navbar-toggler-icon i {
    color: $color-text-quaternary;
  }
}

@media screen and (max-width: 768px) {

}

main {
  margin-top: $navbar-min-height;
}

